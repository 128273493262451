import { ref } from 'vue'
import { defineStore } from 'pinia'
import config from '@/config/config'
import axios from 'axios'
import type { CartOrder } from '@/models/cartOrder'

export const useUserContentOrdersStore = defineStore('userContentOrders', () => {
  const lastSearch = ref(undefined as string | undefined);
  const hasHext = ref(false);
  const controller = ref(null as AbortController | null);

  const orders = ref([] as CartOrder[])

  const next = async () => {
    if(lastSearch.value == undefined) return;
    const params = JSON.parse(lastSearch.value);
    params['page'] = (params['page'] ?? 0) + 1;
    const res = await axios.get<CartOrder[]>(config().BASEURL + config().GETORDERS, {
      params: params,
      headers: { Authorization: ('Bearer ' + localStorage.getItem('loginToken')) as string }
    })
    orders.value = orders.value.concat(res.data);
    if(res.data.length == params.size ?? 20) hasHext.value = true;
  };
  
  const load = async (orderId?: string, courseId?: string, search?: string, size = 20, force=false) => {
    const params = {
      size: size
    } as { [key: string]: string | number };

    if (orderId) params['orderId'] = orderId;
    if (courseId) params['courseId'] = courseId;
    if (search) params['search'] = search;
    if (!lastSearch.value || lastSearch.value != JSON.stringify(params) || force) {
      
      if(controller.value) controller.value.abort();
      controller.value = new AbortController();
      const res = await axios.get<CartOrder[]>(config().BASEURL + config().GETORDERS, {
        params: params,
        signal: controller.value.signal,
        headers: { Authorization: ('Bearer ' + localStorage.getItem('loginToken')) as string }
      })
      orders.value = res.data;
      if(res.data.length == size) hasHext.value = true;
      lastSearch.value = JSON.stringify(params);
    }
  }

  return { load, orders, next, hasHext}
})
